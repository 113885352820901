.beneficios-flex{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.beneficios-itens h2 {
    margin: 0 0 30px 0;
}

.beneficios-itens ul {
    margin: 0;
    padding: 0;
}

.beneficios-itens li{
    list-style-type: none;
    color: #777;
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.beneficios-itens li img {
    width: 15px;
    margin-right: 10px;
}

.w-500 {
    width: 500px;
}
.w-700 {
    width: 700px;
}

@media (max-width: 450px) {
    .w-500 {
        width: 300px;
    }
    .w-700 {
        width: 300px;
    }
}