.contato {
    margin: auto;
    padding: 100px 200px 30px 200px;
}

.container-contato {
    background-color: #F1F1F1;
    border-radius: 20px;
    border: 1px solid #354056;
    display: flex;
    justify-content: space-between;
    padding: 0px 50px 0 0px;
}
.container-contato img {
    height: 100%;
}
.container-contato h1 {
    color: #354056;
}
.container-contato p {
    color: #777;
    line-height: 30px;
}
.content-contato {
    width: 50%;
    padding: 80px 0;
}
.content-contato label {
    color: #777;
    font-size: 15px;
}
.content-contato a {
    color: #777;
    transition: .5s;
}
.content-contato a:hover {
    color: #354056;
}
.input-contato {
    display: flex;
    flex-direction: column;
}
.input-contato input,
.input-contato select {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #999999;
    padding: 10px 0;
    margin-bottom: 20px;
    width: 100%;
    background-color: transparent;
    color: #777;
}
.input-contato span {
    font-size: 14px;
    color: #777;
    margin: 10px 0 20px 0;
}

.input-interno {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1440px) {
    .contato {
        padding: 30px 200px;
    }
}

@media (max-width: 1080px){
    .contato {
        padding: 30px 100px;
    }
    .container-contato {
        padding: 0;
    }
}

@media (max-width: 720px) {
    .contato {
        padding: 65px 50px 30px 50px;
    }
    .container-contato img {
        display: none;
    }
    .content-contato {
        width: 100%;
        padding: 30px 20px;
    }
}