.botao {
    background-color: #277C82;
    border: none;
    border-radius: 5px;
    color: #F1F1F1;
    text-align: center;
    font-weight: 700;
    padding: 10px 15px;
    cursor: pointer;
}

.botao-branco {
    background-color: #fff;
    border: 1px solid #277C82;
    border-radius: 5px;
    color: #277C82;
    text-align: center;
    font-weight: 700;
    padding: 10px 15px;
    cursor: pointer;
}

.botao-simples-nacional {
    border: none;
    margin-top: 20px;
    background: linear-gradient(20deg, #419D12, #E8E907);
    color: white;
    border-radius: 5px;
    padding: 10px 60px;
    cursor: pointer;
    font-weight: bold;
}