.modal {
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 6;
    box-sizing: border-box;
}

.modal h1 {
    margin: 0;
    font-size: 22px;
}

.modal-interno {
    display: flex;
    flex-direction: column;
    width: 40%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}

.modal-interno p {
    word-break: break-all;
}

.pix {
    width: 300px;
    border: 10px solid rgb(0, 183, 169);
    border-radius: 10px;
}

.icon-close {
    font-size: 20px;
}

.btn-close-modal {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    transition: .5s;
}

.btn-close-modal:hover {
    background-color: red;
    color: white;
}

.btn-copiar {
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}

.btn-copiar:hover {
    background-color: #c7c7c7;
}

.copy {
    font-size: 13px;
    margin: 5px 0;
}

.logo-pix {
    width: 200px;
}

.botao-pix {
    width: 320px;
    margin: 0;
}