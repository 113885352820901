body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

html {
  scroll-behavior: smooth;
}
