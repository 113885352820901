.sucesso {
    background-color: #00cb9f;
    color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    position: fixed;
    top: 90px;
    right: 10px;
    z-index: 5;
    font-size: 18px;
}

.alert-erro {
    background-color: #df2121;
    color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    position: fixed;
    top: 90px;
    right: 10px;
    z-index: 5;
}

.sucesso p,
.alert-erro p {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
}