.beneficios {
    margin: auto;
    padding: 80px 200px;
}

.beneficios h2 {
    text-align: center;
    color: #354056;
}

.container-beneficios,
.container-servicos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.card-beneficios {
    padding-top: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #fafafa;
    width: 32%;
}

.card-beneficios h3,
.card-servicos h3 {
    color: #354056;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}
.card-beneficios p,
.card-servicos p {
    color: #354056;
    text-align: center;
}

.card-servicos {
    padding-top: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #fafafa;
    width: 23%;
}