.bank-banner {
    background-image: url(../../images/ExactusBank/bank5.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    align-items: center;
    background-position-x: center;
}

.bank-banner h1 {
    color: #354056;
    font-family: 'BigNoodleTitling', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 100px;
    margin: 0;
    line-height: 80px;
}

.bank-banner h2 {
    color: #354056;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
}

.bank-banner p {
    color: #354056;
}

.container-banner-bank {
    border: 1px solid #ffffff;
    width: 60%;
    border-radius: 0 20px 20px 0;
    background: linear-gradient(119deg, #FFF 3.61%, rgba(255, 255, 255, 0.00) 110.58%);
    backdrop-filter: blur(2px);
}

.content-bank {
    width: 80%;
    float: right;
    padding: 50px 0;
}

.content-bank p {
    max-width: 500px;
    padding-right: 20px;
}

.content-ecpf h4 {
    color: #354056;
    margin-bottom: 0;
}

.banner-end-bank {
    background-image: url(../../images/ExactusBank/banner\ end.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
}

.banner-end-bank-blue {
    background-color: #273156;
    height: 100%;
    width: 40%;
    /* mix-blend-mode: multiply; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner-end-bank-div {
    width: 300px;
}

.banner-end-bank h2 {
    margin: 0 0 15px 0;
    color: #fff;
}

.cadastro-bank {
    border-radius: 20px;
    border: 1px solid #BCBBB6;
    background: #F1F1F1;
    padding: 30px;
    margin-bottom: 80px;
}

.cadastro-bank h2 {
    color: #354056;
}

.cadastro-bank p {
    color: #777;
}

.cadastro-bank h2,
.cadastro-bank p {
    text-align: center;
}

.cadastro-bank label {
    color: #777;
}

.section-cadastro-bank {
    display: flex;
    justify-content: space-between;
    padding: 80px 200px 0 200px;
    width: 60%;
    margin: auto;
}

.section-cadastro-bank img {
    width: 430px;
}

@media (max-width: 1080px) {
    .section-cadastro-bank {
        padding: 50px 100px 0px 100px;
        width: 80%;
    }
}

@media (max-width: 720px) {
    .section-cadastro-bank {
        padding: 30px 50px 0px 50px;
        flex-direction: column;
    }

    .container-banner-bank {
        width: 90%;
    }

    .bank-banner h1 {
        line-height: 50px;
        font-size: 55px;
    }

    .container-ecpf {
        flex-direction: column;
        align-items: center;
    }

    .content-ecpf {
        width: 80%;
    }

    .banner-end-bank-blue {
        width: 60%;
    }

    .section-cadastro-bank img {
        width: 300px;
    }
}

@media (max-width: 450px) {
    .container-banner-bank {
        width: 99%;
    }

    .banner-end-bank-blue {
        width: 90%;
    }
}