@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.home {
    padding-top: 75px;
}
.home-banner {
    background-image: url(../../images/Home/homebanner.jpg);
    background-size: cover;
    height: 70vh;
    display: flex;
    align-items: center;
}

.home-banner h1 {
    color: #354056;
    font-family: 'BigNoodleTitling', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 85px;
    margin: 0;
    line-height: 80px;
}

.home-banner h2 {
    color: #777777;
    font-weight: 400;
    font-size: 20px;
}

.container-banner {
    border: 1px solid #ffffff;
    width: 40%;
    border-radius: 0 20px 20px 0;
    background: linear-gradient(119deg, #FFF 3.61%, rgba(255, 255, 255, 0.00) 110.58%);
    backdrop-filter: blur(2px);
}

.content-banner {
    width: 50%;
    float: right;
    padding: 50px 0;
}

.home-banner-simples-nacional{
    background-image: url(../../images/SimplesNacional/banner_home.svg); 
    position: relative;
    height: 70vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

.content-banner-simples-nacional {
    position: absolute;
    top: 45%;
    left: 35%; 
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-banner-simples-nacional p {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #353435;
    margin-bottom: 0;
}

.logo-simples-nacional {
    width: 250px;
    height: 200px;
}

.logo-pessoa-simples-nacional {
    position: absolute;
    height: 69vh;
    top: 60%;
    left: 100%;
    transform: translate(0%, -50%);    
}

.btn-saiba-mais {
    background-color: #419D12;
    width: 278px;
    border: none;
    border-radius: 5px;
    height: 50px;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 19px;
    cursor: pointer;
}

.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 70vh; 
}

.carousel-item {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    transition: transform 1s ease;
    opacity: 0;
    visibility: hidden;
}

.carousel-item.active {
    left: 0;
    opacity: 1;
    visibility: visible;
}

.carousel-indicators {
    position: absolute;
    width: 100%;
    justify-content: center;
    top: 74%;
    display: flex;
    gap: 10px;
    z-index: 2;
}

.carousel-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.carousel-dot.active {
    background-color: #333;
}

.home-contato,
.home-solucoes,
.home-certificado,
.home-ajuda,
.home-conheca,
.home-banner-final {
    margin: auto;
    padding: 80px 200px;
}

.home-contato h2,
.home-contabilidade h2,
.home-solucoes h2,
.home-certificado h2,
.home-ajuda h2,
.home-conheca h2,
.home-banner-final h2 {
    color: #354056;
    font-size: 24px;
}

.home-contabilidade h3,
.home-solucoes h3,
.home-certificado h3 {
    color: #354056;
    font-size: 20px;
}

.home-contato p,
.home-contabilidade p,
.home-solucoes p,
.home-certificado p,
.home-ajuda p,
.home-conheca p,
.home-banner-final p {
    color: #777777;
    line-height: 25px;
}

.home-contabilidade,
.home-certificado,
.home-conheca {
    background-color: #F1F1F1;
}

.home-conheca h3 {
    margin: 0;
    color: #9E2021;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 70px;
    text-align: center;
}

.home-conheca span {
    font-size: 30px;
    font-weight: 200;
}

.container-contabilidade,
.container-certificado,
.container-solucoes,
.container-ajuda,
.container-conheca {
    display: flex;
    justify-content: space-between;
}

.container-contato-home,
.container-certificado,
.container-ajuda {
    margin-bottom: 50px;
}

.home-contabilidade {
    margin: auto;
    padding: 80px 200px 80px 200px;
}

.card-contablidade,
.card-certificado {
    width: 24%;
    padding: 30px 35px;
    box-sizing: border-box;
    border: 1px solid #777777;
    border-radius: 10px;
    background-color: #ffffff;
}

.card-contablidade {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-contablidade h3 {
    margin: 0;
}

.card-contablidade p {
    margin: 15px 0 30px 0;
}

.card-solucoes,
.card-ajuda {
    width: 26%;
    padding: 30px 35px;
    background-color: #f1f1f1;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
}

.card-solucoes {
    margin-bottom: 20px;
}

.elemento-centralizado {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-banner-final {
    background-image: url('../../images/Home/bannerend.webp');
    background-repeat: no-repeat;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: end;
}

.container-banner-final {
    text-align: end;
    width: 250px;
}

.container-banner-final h2 {
    margin-bottom: 50px;
}

@media (max-width: 1440px) {

    .home-contato,
    .home-solucoes,
    .home-certificado,
    .home-ajuda,
    .home-conheca,
    .home-banner-final {
        padding: 80px 200px;
    }

    .card-ajuda,
    .card-solucoes {
        width: 24%;
    }

    .container-banner {
        width: 50%;
    }

    .logo-simples-nacional {
        width: 210px;
        height: 170px;
    }
}

@media (max-width: 1080px) {
    .container-banner {
        width: 60%;
    }

    .content-banner {
        width: 63%;
    }

    .logo-simples-nacional {
        width: 180px;
        height: 140px;
    }

    .home-banner-simples-nacional {
        background-position: top;
    }

    .home-contato,
    .home-solucoes,
    .home-certificado,
    .home-ajuda,
    .home-conheca,
    .home-banner-final {
        padding: 50px 100px;
    }

    .home-contabilidade {
        padding: 50px 100px 50px 100px;
    }
}

@media (max-width: 720px) {
    .container-banner {
        width: 95%;
    }

    .logo-simples-nacional {
        width: 160px;
        height: 120px;
    }

    .content-banner-simples-nacional p {
        font-size: 16px;
    }

    .home-contato,
    .home-solucoes,
    .home-certificado,
    .home-ajuda,
    .home-conheca,
    .home-banner-final {
        padding: 30px 50px;
    }

    .home-contabilidade {
        padding: 30px 50px 30px 50px;
    }

    .container-contabilidade,
    .container-solucoes,
    .container-certificado,
    .container-ajuda {
        flex-direction: column;
    }

    .card-contablidade,
    .card-certificado {
        width: 100%;
        margin-bottom: 20px;
    }

    .card-solucoes,
    .card-ajuda {
        width: 90%;
        margin-bottom: 20px;
    }

    .home-conheca h3 {
        font-size: 50px;
    }
}

@media (max-width: 450px) {
    .home {
        padding-top: 55px;
    }
    .home-banner h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .content-banner {
        padding: 20px 0;
        width: 80%;
    }

    .content-banner-simples-nacional {
        left: 50%;
    }

    .logo-simples-nacional {
        padding-top: 100px;
        width: 300px;
        height: 170px;
    }

    .logo-pessoa-simples-nacional {
        display: none;
    }

    .card-solucoes,
    .card-ajuda {
        width: 80%;
    }
    .container-conheca {
        flex-direction: column;
    }
    .home-conheca p {
        text-align: center;
    }
}