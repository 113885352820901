/* ESTILIZAÇÃO BANNER */
.banner-simples-nacional {
    padding-top: 150px;
    background-repeat: no-repeat;
    background-position-x: 70%;
    background-position-y: center;
    background-size: 35%;
    height: 50vh;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    position: relative;
    z-index: 0;
}

.conteudo-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 80px;
    position: relative;
}

/* ESTILIZAÇÃO DO VETOR-1 */
.vetor-1 {
    background-image: url(../../images/SimplesNacional/frame-1.svg);
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
}

.conteudo-vetor-1 {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.info-vetor-1 {
    margin-top: 50px;
    width: 700px;
    height: 500px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-size: 16px;
}

/* botão branco que fica em cima do vetor 1 */
.info-vetor-1 button {
    border: none;
    background-color: white;
    color: #419D12;
    border-radius: 5px;
    padding: 10px 60px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

.info-vetor-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.titulo-info-vetor-1 {
    font-size: 35px;
}

.info-vetor-1 div {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.info-vetor-1 div img {
    margin-bottom: 30px;
}

.info-2-vetor-1 {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 50px;
    text-align: center;
}

.info-2-vetor-1 img {
    width: 60px;
    height: 60px;
}

.conteudo-info-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.info-titulos {
    font-size: 30px;
    margin: 0px;
}

.info-msg {
    width: 70%;
}

.video-vetor-1 {
    margin-top: 120px;
    margin-bottom: 190px;
}

/* ESTILIZAÇÃO DO P-1 */
.p-1 {
    text-align: center;
    font-size: 25px;
    z-index: 0;
    position: relative;
}

.conteudo-p-1 img {
    width: 60px;
    height: 60px;
}

/* ESTILIZAÇÃO DO VETOR-2 */
.vetor-2 {
    background-image: url(../../images/SimplesNacional/frame-2.svg);
    min-height: 1450px;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.conteudo-vetor-2 {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.texto-vetor-2 {
    text-align: start;
}

.titulo-vetor-2 {
    font-size: 25px;
    text-align: start;
}

.conteiner-titulo-vetor-2 {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.conteudo-vetor-2 span {
    font-size: 85px;
    font-weight: bold;
}

.conteudo-vetor-2 img {
    justify-content: start;
    width: 50px;
    height: 50px;
}

.esquerda-vetor-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    width: 400px;
    margin-top: 20px;
    gap: 150px;
}

.esquerda-vetor-2 img,
.direita-vetor-2 img {
    margin-right: 150px;
}

.direita-vetor-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    width: 400px;
    gap: 150px;
}

/* ESTILIZAÇÃO DO P-2 */
.p-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 25px;
    z-index: 0;
    position: relative;
    margin-bottom: 80px;
}

.conteudo-p-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
}

.conteudo-p-2 img {
    width: 60px;
    height: 60px;
}

.p-2 button {
    margin-top: 25px;
    border: none;
    background: linear-gradient(20deg, #419D12, #E8E907);
    color: white;
    border-radius: 5px;
    padding: 10px 60px;
    cursor: pointer;
    font-weight: bold;
}

/* divisão raquel */
/* ESTILIZAÇÃO VETOR-3*/
.vetor-3 .imagem-fundo {
    background-image: url('../../images/SimplesNacional/frame-3.svg');
    background-size: cover;
    height: 120vh;
    display: flex;
    align-items: center;
}

.vetor-3 .content {
    text-align: center;
    width: 100%;
    padding: 60px 0;
}

.vetor-3 .content .modulos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    gap: 10px;
}

.vetor-3 .content .modulos img {
    width: 100%;
    max-width: 580px;
    height: auto;
}

.texto {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 850px;
    height: auto;
}

.texto h2 {
    font-family: 'Raleway', sans-serif;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
}

/* ESTILIZAÇÃO FORMULARIO */
.formulario {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
}

.form-container {
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group input,
.input-mask {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 5px;
}

/* Grupo Inline */
.inline-group {
    display: flex;
    gap: 10px;
}

.checked-group {
    display: flex;
    font-family: 'Roboto', sans-serif;
    align-items: center;
    color: #777777;
}

@media (max-width: 1080px) {
    #img-modelo {
        display: none;
    }
}

/* Para telas com largura máxima de 720px */
@media (max-width: 720px) {
    #img-modelo {
        display: none;
    }

    .info-vetor-1 {
        margin-top: 80px;
        width: 90%;
        font-size: 16px;
    }

    .titulo-info-vetor-1 {
        font-size: 20px;
    }

    /* info-2-vetor-1 */

    .info-2-vetor-1 {
        flex-direction: column;
    }

    .info-titulos {
        font-size: 20px;
    }

    .info-2-vetor-1 img {
        width: 40px;
        height: 40px;
    }

    .video-vetor-1 iframe {
        width: 330px;
        height: 210px;
        margin-bottom: 50px;
    }

    /* p-1 */

    .conteudo-p-1 img {
        width: 40px;
        height: 40px;
    }

    .conteudo-p-1 p {
        font-size: 20px;
    }

    /* vetor-2 */

    .conteudo-vetor-2 span {
        font-size: 55px;
    }

    .texto-vetor-2 {
        font-size: 12px;
    }

    .titulo-vetor-2 {
        font-size: 16px;
    }

    .conteudo-vetor-2 img {
        width: 30px;
        height: 30px;
    }

    .esquerda-vetor-2,
    .direita-vetor-2 {
        width: 80%;
    }

    /* p-2 */
    .p-2 {
        margin-bottom: 100px;
    }

    .conteudo-p-2 {
        gap: 20px;
    }

    .conteudo-p-2 img {
        width: 30px;
        height: 30px;
    }

    .conteudo-p-2 p {
        font-size: 16px;
    }

    /* vetor-3 */

    .vetor-3 .content .texto h2 {
        font-size: 25px;
        line-height: 30px;
    }

    .vetor-3 .content .modulos img {
        max-width: 100%;
    }

    .vetor-3 .content .texto {
        width: 60%;
    }

    .form-container {
        padding: 15px;
    }

    .texto h2 {
        font-size: 16px;
    }

    .inline-group {
        flex-direction: column;
    }

    .form-container {
        padding: 15px;
    }

}

@media (max-width: 400px) {

    .conteudo-vetor-2 span {
        font-size: 30px;
    }

    .texto-vetor-2 {
        font-size: 12px;
        width: 100%;
    }

    .titulo-vetor-2 {
        font-size: 14px;
    }

    .conteiner-titulo-vetor-2 {
        width: 100%;
    }

    .conteudo-vetor-2 img {
        width: 20px;
        height: 20px;
        margin-right: 60px;
    }

    .esquerda-vetor-2,
    .direita-vetor-2 {
        width: 100%;
    }

}