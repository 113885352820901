.planos,
.planos-imagem {
    margin: auto;
    padding: 80px 200px;
}

.planos h2,
.planos-imagem h2 {
    color: #354056;
    font-size: 24px;
}

.planos-imagem h2 {
    text-align: center;
}

.planos p,
.planos-imagem p {
    color: #777;
}

.planos-img {
    margin-bottom: 15px;
    width: 60px;
}

@media (max-width: 1440px) {

    .planos,
    .planos-imagem {
        padding: 80px 200px;
    }

    .card-razoes {
        width: 25%;
    }
}

@media (max-width: 1080px) {
    .planos,
    .planos-imagem {
        padding: 50px 100px;
    }
}

@media (max-width: 720px) {
    .planos,
    .planos-imagem {
        padding: 50px 50px;
    }
}