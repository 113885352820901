.loading-screen {
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.loader-container {
    width: 32px;
    height: 32px;
}

.spinner {
    width: 32px;
    height: 32px;
    border: 3px solid;
    border-color: #fff transparent #fff transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}