.erp h2 {
    margin: 0;
    text-align: center;
}
.erp h3 {
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
}

.container-erp {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.container-erp-menor {
    display: flex;
    margin: 30px auto 0 auto;
    justify-content: space-between;
    width: 60%;
}

.container-erp p {
    color: #354056;
    line-height: 30px;
    text-align: center;
}

.content-erp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.card-erp {
    background-color: #F1F1F1;
    border-radius: 20px;
    padding: 30px 35px;
    width: 25%;
}

.card-erp img {
    height: 50px;
}

.card-erp h3 {
    text-align: center;
    color: #354056;
    font-size: 20px;
}

.card-erp p {
    line-height: 30px;
    color: #777;
    text-align: start;
}

.card-erp-branco {
    background-color: #fff;
    width: 27%;
    border-radius: 20px;
    padding: 30px 35px;
}

.card-erp-saibamais {
    background-color: #fff;
    width: 18%;
    border-radius: 20px;
    padding: 30px 35px;
}

.card-erp-branco-menor {
    background-color: #fff;
    width: 40%;
    border-radius: 20px;
    padding: 30px 35px;
}

.card-erp-branco h3{
    text-transform: uppercase;
}
.card-erp-saibamais h3 {
    text-transform: uppercase;
    text-align: center;
}
.card-erp-branco p,
.card-erp-saibamais p {
    color: #777;
    text-align: start;
}

.stone {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}

.stone div:not(:last-child){
    width: 50%;
}
.stone img {
    width: 400px;
    height: 300px;
}

.stone p {
    line-height: 30px;
    color: #354056;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1440px) {
    .card-erp-branco {
        width: 25%;
    }
    .card-erp-saibamais {
        width: 16%;
    }
    .card-erp-branco-menor {
        width: 37%;
    }
}
@media (max-width: 1080px){
    .container-erp-menor {
        width: 65%;
    }
}
@media (max-width: 720px) {
    .container-erp,
    .container-erp-menor {
        flex-direction: column;
        align-items: center;
    }
    .container-erp-menor {
        width: 90%;
    }
    .card-erp,
    .card-erp-branco,
    .card-erp-saibamais {
        width: 90%;
        margin-bottom: 10px;
    }
    .card-erp-branco-menor {
        width: 100%;
        margin-bottom: 10px;
    }
    .stone div:not(:last-child){
        width: 100%;
    }
    .stone img {
        width: 300px;
        height: 200px;
    }
    .d-flex {
        flex-direction: column;
    }
}