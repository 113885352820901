.planos {
    margin: auto;
    padding: 80px 200px;
}

.planos-contabilidade h2,
.planos-contato h2 {
    color: #354056;
    font-size: 24px;
    text-align: center;
}

.plano-academico h2,
.plano-fit h2,
.plano-plus h2,
.plano-empresarial h2,
.plano-maxx h2,
.planos-comparativo h2 {
    color: #354056;
    font-size: 24px;
}

.plano-academico h3 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 3.5px;
    margin: 0;
    color: #273156;
}

.planos-contabilidade p,
.plano-academico p,
.plano-fit p,
.plano-plus p,
.plano-empresarial p,
.plano-maxx p,
.planos-contato p {
    color: #777777;
    line-height: 25px;
}

.plano-academico,
.plano-plus,
.plano-maxx,
.planos-contato {
    background-color: #F1F1F1;
}

.container-academico,
.container-fit,
.container-plus,
.container-empresarial {
    display: flex;
    justify-content: space-between;
}

.container-empresarial a {
    text-decoration: none;
}

.container-maxx {
    display: flex;
}

.container-maxx .card {
    margin-right: 30px;
}

.card-empresarial {
    width: 24%;
    padding: 30px 35px;
    box-sizing: border-box;
    border: 1px solid #777777;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 50px;
}

.card-academico h4,
.container-fit h4,
.container-plus h4,
.container-empresarial h4 {
    color: #354056;
    text-align: center;
}

.container-comparativo {
    border: 1px solid #BCBBB6;
    border-radius: 20px;
    padding: 30px 50px;
    background-color: #F1F1F1;
}

.container-comparativo table {
    background-color: #fff;
    border: 1px solid #BCBBB6;
    border-radius: 10px;
    border-spacing: 0;
    margin-top: 50px;
}

.container-comparativo th {
    color: #354056;
    font-size: 18px;
    text-align: start;
    padding: 30px 35px;
    border-bottom: 1px solid #BCBBB6;
}
.container-comparativo th:not(:first-child),
.container-comparativo td:not(:first-child) {
    border-left: 1px solid #BCBBB6;
    text-align: center;
}
.container-comparativo td {
    color: #354056;
    padding: 10px 35px;
    border-bottom: 1px solid #BCBBB6;
}

.container-comparativo img {
    width: 22px;
    height: 22px;
}

@media (max-width: 1080px){
    .container-comparativo th {
        padding: 10px 15px;
    }
}

@media (max-width: 720px) {
    .container-comparativo {
        padding: 15px 15px;
    }
    .container-comparativo img{
        width: 15px;
        height: 15px;
    }
    .container-comparativo th,
    .container-comparativo td {
        padding: 5px;
        font-size: 15px;
    }
    .container-comparativo table {
        margin-top: 10px;
    }

    .container-empresarial, .container-academico, .container-fit, .container-plus {
        flex-direction: column;
    }

    .container-academico .card, .container-fit .card, .container-plus .card {
        width: 100%;
    }

    .card-empresarial {
        width: 100%;
    }
}