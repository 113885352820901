.plano-modulos {
    margin: auto;
    padding: 80px 200px;
    background-color: #f1f1f1;
}

.plano-modulos h2 {
    color: #354056;
    font-size: 24px;
    margin: 0 0 50px 0;
}

.plano-modulos h3 {
    color: #354056;
    font-family: 'BigNoodleTitling', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 100px;
    margin: 0;
}

.plano-modulos h4 {
    color: #354056;
    text-transform: uppercase;
    font-size: 24px;
    margin: 0;
}

.plano-modulos p {
    color: #777;
    line-height: 25px;
}

.container-modulos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}
.container-beneficios {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    width: 50%;
}

.content-modulos-direita {
    text-align: end;
    width: 60%;
}

.content-modulos-esquerda {
    width: 60%;
}

.beneficios-modulos {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.beneficios-modulos span {
    display: flex;
    align-items: center;
    justify-content: end;
    color: #354056;
    margin: 5px 0;
    font-weight: 700;
}

.beneficios-modulos span img  {
    margin-left: 10px;
}