.page-compra {
    background-color: #E3E3E3;
    padding: 90px 100px 80px !important;
    margin: auto;
}
.page-compra h2 {
    margin-top: 0;
    color: #354056;
}

.compra {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
}

.container-descricoes {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.container-compra h2 {
    margin-top: 0;
    color: #354056;
}

.content-compra {
    background-color: #f1f1f1;
    border-radius: 12px;
    padding: 30px;
}

.content-compra h2 {
    text-align: center;
    color: #354056;
    font-weight: 400;
    font-size: 30px;
    margin-top: 0;
}

.content-compra h3 {
    text-align: center;
    color: #354056;
    margin-bottom: 30px;
}

.content-compra h4 {
    color: black;
    font-size: 30px;
    margin: 15px 0;
    text-align: center;
}

.content-compra p {
    color: #354056;
    text-align: center;
}

.valor-antigo {
    color: #A8A6A6;
}

.pagamento-compra {
    background-color: #f1f1f1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 400px;
}

.pagamento-compra h2 {
    margin: 0;
    font-size: 18px;
    color: #354056;
}

.pagamento-compra img {
    margin-left: 15px;
    width: 70px;
    height: 50px;
}

.pagamento-compra-dados {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    width: 400px;
}

.compra-finalizada {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 55vh;
    padding-top: 75px;
}

.compra-finalizada h2,
.compra-finalizada p {
    color: #354056;
}

.compra-finalizada h2 {
    margin: 0;
}

.compra-finalizada p {
    margin: 0 0 10px 0;
}

.compra-finalizada a {
    text-decoration: none;
    margin-top: 50px;
}

.input-compra {
    display: flex;
    justify-content: space-between;
}

.icon-alert {
    text-align: center;
    font-size: 50px;
    color: rgb(197, 21, 21);
}

.erro {
    margin: 0 0 15px 0;
    color: red;
    display: flex;
    align-items: center;
}

@media (max-width: 1440px) {
    .page-compra {
        padding: 90px 200px 80px 200px;
    }
}

@media (max-width: 1080px) {
    .page-compra {
        padding: 80px 150px 80px 150px;
    }
}
@media (max-width: 720px) {
    .page-compra {
        padding: 80px 50px 30px 50px;
    }
    .pagamento-compra {
        width: 300px;
    }
}

@media (max-width: 450px) {
    .compra-finalizada {
        padding-top: 55px;
    }
    .compra {
        flex-direction: column;
    }
    .content-compra {
        margin: 0 auto 30px auto;
    }
    .page-compra {
        height: 150vh;
    }
    .pagamento-compra-dados {
        width: 300px;
    }
    .input-compra {
        flex-direction: column;
    }
}