.navbar {
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    z-index: 5;
}

.container {
    display: flex;
    align-items: center;
    padding: 0 200px;
}

.container img {
    width: 180px;
    height: 43px;
}

.menu-icon {
    display: none;
}

.nav-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 200px;
    width: 100%;
}

.nav-elements ul {
    list-style-type: none;
    display: flex;
}

.nav-elements ul li {
    width: 110px;
    text-align: center;
}

.nav-elements-inicial li {
    margin-right: 30px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #273156;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #273156;
    font-weight: 500;
    position: relative;
}

/*submenu*/

.dropbtn {
    background-color: #fff;
    color: #273156;
    font-size: 16px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    transition: .5s;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: start;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    border-radius: 0px 0px 10px 10px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {
    font-weight: 400;
} */

@media(max-width: 1640px) {
    .container {
        padding: 0 100px;
    }
}

@media (max-width: 1440px) {
    .container {
        padding: 0 50px;
    }

    .nav-elements {
        margin-left: 90px;
    }
}

@media (max-width: 1300px) {
    .nav-elements {
        margin-left: 40px;
    }
}

@media (max-width: 1210px) {
    .container {
        padding: 0px;
    }
    .nav-elements ul {
        padding-inline-start: 0;
    }
}

@media (max-width: 1100px) {
    .nav-elements {
        margin-left: 0px;
    }
}

@media (max-width: 1080px) {
    .container {
        padding: 0 10px;
    }
    .nav-elements ul li {
        width: 104px;
    }
    .nav-elements-inicial li {
        margin-right: 10px;
    }
}

@media (max-width: 950px) {
    .container {
        justify-content: space-between;
        padding: 0 30px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
        font-size: 40px;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 45px;
        background-color: #fff;
        width: 0px;
        height: calc(100vh - 45px);
        transition: all 0.3s ease-in;
        overflow: hidden;
        flex-direction: column;
        justify-content: flex-start;
    }

    .nav-elements.active {
        width: 270px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

    .dropdown-content {
        min-width: 270px;
        left: -80px;
    }
}