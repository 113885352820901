footer {
    background-color: #354056;
    color: #ffffff;
    padding: 60px 200px 20px 200px;
}

footer ul {
    margin: 0;
}

footer li {
    list-style: none;
    font-weight: lighter;
    letter-spacing: 0.353px;
    line-height: 25px;
}

footer p {
    margin: 0;
}

.footer-itens {
    display: flex;
    justify-content: space-between;
}

.footer-paginas li {
    margin-bottom: 15px;
}
.footer-paginas a {
    text-decoration: none;
    color: #ffffff;
    transition: .5s;
}
.footer-paginas a:hover {
    color: #ffffff80;
}
.footer-parceiros {
    margin-bottom: 50px;
}

.footer-redes-sociais {
    width: 200px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

.footer-final { 
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.logo {
    width: 190px;
    height: 46px;
}

@media (max-width: 1080px) {
    footer {
        padding: 60px 50px 20px 50px;
    }
}
@media (max-width: 720px) {
    footer {
        padding: 10px 0;
    }
    .footer-redes-sociais {
        width: 100px;
    }
    .footer-redes-sociais img {
        width: 20px;
    }
    .footer-paginas {
        padding-inline-start: 10px;
    }
}

@media (max-width: 450px) {
    .footer-itens {
        flex-direction: column;
        margin: 20px 50px;
    }
    footer ul {
        padding: 0;
    }
    .footer-paginas {
        margin-top: 20px;
        padding-inline-start: 0px;
    }
}