.banner-institucional {
    background-image: url('../../images/Institucional/aboutusbanner.png');
    background-size: cover;
    height: 70vh;
    padding-top: 75px;
}

.banner-institucional h1 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-family: 'BigNoodleTitling', sans-serif;
    font-size: 150px;
    text-shadow: 0px 4px 50px #D7B899;
    font-weight: 400;
    padding-top: 100px;
}

.institucional-meio {
    text-align: center;
}

.institucional-meio h2 {
    font-weight: 400;
}

.content-institucional {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-institucional div {
    width: 50%;
}

.content-institucional img {
    width: 500px;
    height: 360px;
}

.content-institucional h2 {
    font-family: 'BigNoodleTitling', sans-serif;
    font-size: 100px;
    font-weight: 400;
    text-align: start;
    margin: 0;
}

.container-historia {
    margin-bottom: 100px;
}

.content-historia {
    padding: 0 200px;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: -50px;
}

.content-historia h2 {
    font-size: 24px;
}

.content-historia p {
    color: #777;
    line-height: 30px;
}

.content-historia div {
    width: 40%;
}

.content-historia img {
    width: 45%;
}
.razoes-historia {
    padding: 80px 200px 1px;
}
.fundacao {
    background-color: #5E5E3C;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: end;
    padding: 30px 200px;
}

.associacao {
    background-color: #6E6550;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: start;
    padding: 30px 200px;
}

.instalacao {
    background-color: #CA513B;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: end;
    padding: 30px 200px;
}

.expansao {
    background-color: #C8452E;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: end;
    padding: 30px 200px;
}

.cpi {
    background-color: #B03C27;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: start;
    padding: 30px 200px;
}

.adaptacao {
    background-color: #B0321B;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: end;
    padding: 30px 200px;
}

.criacao {
    background-color: #B03C27;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: start;
    padding: 30px 200px;
}

.nacional {
    background-color: #B0241B;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: end;
    padding: 30px 200px;
}

.inovacao {
    background-color: #AD180E;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: start;
    padding: 30px 200px;
}

.tecnologias {
    background-color: #AD180E;
    color: #fff;
    font-size: 80px;
    font-weight: 300;
    text-align: end;
    padding: 30px 200px;
}

.banner-end {
    background-image: url('../../images//Institucional/bannerend.png');
    height: 70vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -100px;
}

.institucional-end {
    background: linear-gradient(96deg, #D90000 0.98%, #E30C4D 36.15%, #0BB2D7 108.93%);
    text-align: center;
    color: #fff;
    padding: 80px 200px;
}

.institucional-end p {
    font-size: 43px;
    margin: 0;
}

.institucional-end h2 {
    font-size: 100px;
    font-weight: 300;
    margin-bottom: 0;
}

.institucional-end h3 {
    font-size: 80px;
    font-weight: 300;
}

.institucional-end span {
    height: 150px;
    border-left: 2px solid white;
    margin: 0 100px;
}

@media (max-width: 1080px) {
    .banner-institucional h1 {
        font-size: 120px;
    }

    .content-institucional h2 {
        font-size: 80px;
    }

    .content-institucional img {
        width: 400px;
        height: 260px;
    }

    .content-historia {
        padding: 0 100px;
    }

    .container-historia {
        margin-bottom: 80px;
    }

    .fundacao,
    .associacao,
    .instalacao,
    .expansao,
    .cpi,
    .adaptacao,
    .criacao,
    .nacional,
    .inovacao,
    .tecnologias {
        font-size: 50px;
        padding: 30px 100px;
    }
    .institucional-end {
        padding: 50px 100px;
    }
    .institucional-end h2 {
        font-size: 80px;
    }
    .institucional-end h3 {
        font-size: 50px;
    }
    .institucional-end p {
        font-size: 30px;
    }
    .institucional-end span {
        height: 100px;
    }
    
}

@media (max-width: 720px) {
    .content-historia {
        padding: 0 50px;
        bottom: -10px;
    }
    .fundacao,
    .associacao,
    .instalacao,
    .expansao,
    .cpi,
    .adaptacao,
    .criacao,
    .nacional,
    .inovacao,
    .tecnologias {
        font-size: 50px;
        padding: 10px 50px;
    }
    .content-institucional img {
        width: 250px;
        height: 160px;
    }
    .content-institucional h2 {
        font-size: 50px;
    }
    .razoes-historia {
        padding: 30px 50px 1px;
    }
    .content-historia img {
        height: 200px;
    }
    .institucional-end p {
        font-size: 20px;
    }
    .institucional-end h2 {
        font-size: 50px;
    }
    .institucional-end h3 {
        font-size: 30px;
    }
    .institucional-end span {
        height: 50px;
        margin: 0px 30px;
    }
}

@media (max-width: 450px) {
    .banner-institucional {
        background-position-x: center;
        padding-top: 55px;
    }
    .banner-institucional h1 {
        font-size: 80px;
    }
    .content-institucional:not(:nth-child(odd)) {
        flex-direction: column;
    }
    .content-institucional:not(:nth-child(2n)){
        flex-direction: column-reverse;
    }
    .content-institucional h2 {
        font-size: 30px;
    }
    .content-institucional img {
        margin: 10px 0;
    }
    .content-institucional div {
        width: 100%;
    }
    .content-historia {
        flex-direction: column;
        bottom: 20px;
    }
    .content-historia img {
        width: 100%;
    }
    .content-historia div {
        width: 100%;
    }
    .banner-end {
        margin-top: -82px;
        background-position-x: center;
    }
    .institucional-end {
        padding: 30px 50px;
    }
    .institucional-end h2 {
        font-size: 30px;
    }
    .institucional-end img {
        width: 200px;
    }
}