.politica {
    margin: auto;
    padding: 155px 200px 80px 200px;
    background-color: #F1F1F1;
    color: #354056;
}

.politica h1,
.politica h2 {
    text-align: center;
}

.politica h2 {
    font-weight: 400;
    margin: 50px 0;
}

@media (max-width: 1440px) {
    .politica {
        padding: 80px 200px;
    }
} 
@media (max-width: 1080px) {
    .politica {
        padding: 50px 100px;
    }
} 
@media (max-width: 720px) {
    .politica {
        padding: 85px 50px 30px 50px;
    }
} 