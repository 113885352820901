.beneficios-fiscal {
    margin: auto;
    padding: 80px 200px;
}

.section-skill {
    margin: auto;
    padding: 80px 200px;
    background-color: #F1F1F1;
}

.beneficios-fiscal h2,
.section-skill h2 {
    color: #354056;
    font-size: 24px;
    text-align: center;
}

.section-skill p {
    color: #777;
    line-height: 30px
}

.container-beneficios-fiscal {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
}

.content-beneficios-fiscal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.content-beneficios-fiscal h3 {
    color: #354056;
    text-align: center;
}

@media (max-width: 1440px) {
    .section-skill {
        padding: 80px 200px;
    }
}

@media (max-width: 1080px) {
    .beneficios-fiscal,
    .section-skill {
        padding: 30px 50px;
    }
    .content-beneficios-fiscal h3 {
        font-size: 15px;
    }
}
@media (max-width: 450px) {
    .container-beneficios-fiscal {
        align-items: center;
    }
}


