.banner,
.banner-azul {
    padding-top: 75px;
}

.banner-azul {
    background-repeat: no-repeat;
    background-position-x: right;
    background-image: url('../../images/ERP/home-3.webp');
}

.banner div {
    margin-left: 200px;
    width: 35%;
}

.banner h1 {
    color: #354056;
    font-family: 'BigNoodleTitling', sans-serif;
    font-weight: 400;
    font-size: 100px;
    margin: 0;
}

.banner-azul h1 {
    color: #E3E3E3;
    font-family: 'BigNoodleTitling', sans-serif;
    font-weight: 400;
    font-size: 80px;
    margin: 0;
}


.banner-azul div {
    background-color: #273156;
    padding: 110px 0 110px 200px;
    width: 50%;
}

.banner h2 {
    margin: 0 0 15px 0;
    font-size: 40px;
    color: #354056;
    font-weight: 100;
    font-family: 'BigNoodleTitling', sans-serif;
}

.banner p {
    color: #354056;
    margin: 0 0 15px 0;
    line-height: 25px;
}

.banner-azul p {
    color: #E3E3E3;
    margin: 0 0 15px 0;
    line-height: 25px;
}

.banner-planos {
    background-image: url(../../images/PlanosAssinatura/planosbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-academico {
    background-image: url(../../images/Planos/planoacademicobanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-empresarial {
    background-image: url(../../images/Planos/planoempresarialbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-fit {
    background-image: url(../../images/Planos/planofitbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-maxx {
    background-image: url(../../images/Planos/planomaxbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-plus {
    background-image: url(../../images/Planos/planoplus.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-contabil {
    background-image: url(../../images/Planos/contabilbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-folha {
    background-image: url(../../images/Planos/folhabanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-fiscal {
    background-image: url(../../images/Planos/fiscalbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-patrimonial {
    background-image: url(../../images/Planos/patrimonialbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-busca {
    background-image: url(../../images/Planos/ebuscabanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-erp {
    background-image: url(../../images/Planos/erpbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-governanca {
    background-image: url(../../images/GovernancaCadastral/govcadastralbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-certify {
    background-image: url(../../images/Certificado/certduvidasbanner.webp);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-busca div,
.banner-erp div {
    width: 20%;
}

.banner-methodus {
    background-image: url(../../images/Planos/methodusbanner.webp);
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: 90%;
    background-size: 20%;
    background-color: #272727;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-eficatus {
    background-image: url(../../images/Planos/efficatusbanner.webp);
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: center;
    background-size: 30%;
    background-color: #272727;
    height: 50vh;
    display: flex;
    align-items: center;
}

.banner-methodus h1,
.banner-eficatus h1 {
    color: #fff;
}

.banner-methodus p,
.banner-eficatus p {
    color: #f1f1f1;
}

.container-texto-banner {
    display: flex;
    align-items: center;
}

.container-texto-banner img {
    margin-left: 15px;
    width: 70px;
    height: 70px;
}

.banner-top {
    display: flex;
    padding: 50px 0;
    align-items: center;
}

.banner-top h2 {
    margin: 0 15px 0 0;
    line-height: 81px;
    font-weight: 700;
    font-family: "Inria Sans", sans-serif;
}

.banner-top h3 {
    color: #277c82;
    font-weight: 700;
    font-size: 40px;
    font-family: "Inria Sans", sans-serif;
    margin-top: 0;
}

.banner-top p {
    color: #777777;
}


@media (max-width: 1080px) {
    .banner div {
        margin-left: 100px;
    }

    .banner-busca div,
    .banner-erp div {
        width: 40%;
    }

    .banner-azul {
        background-size: cover;
    }
}

@media (max-width: 720px) {
    .banner div {
        margin-left: 50px;
        width: 50%;
    }

    .banner-busca div,
    .banner-erp div {
        width: 60%;
    }

    .banner-certify {
        background-position-x: center;
    }

    .banner-azul {
        padding-top: 55px;
    }

    .stone {
        flex-direction: column;
    }

    .banner-top {
        flex-direction: column;
    }
}

@media(max-width: 450px) {
    .banner {
        padding-top: 55px;
    }

    .banner h1 {
        font-size: 60px;
    }

    .banner h2 {
        font-size: 30px;
    }

    .banner-methodus {
        background-position-y: center;
    }

    .container-methodus {
        flex-direction: column;
    }

    .banner-azul div {
        padding: 110px 0 110px 50px;
        width: 80%;
    }
}