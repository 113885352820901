.eficatus {
    margin: auto;
    padding: 80px 200px 80px 200px;
}

.eficatus h2 {
    color: #354056;
    text-align: center;
    font-size: 24px;
}

.eficatus p {
    color: #777;
    line-height: 30px;
}

@media (max-width: 1440px) {
    .eficatus {
        padding: 80px 200px 80px 200px;
    }
}
@media (max-width: 1080px) {
    .eficatus {
        padding: 50px 100px 50px 100px;
    }
}
@media (max-width: 720px) {
    .eficatus {
        padding: 50px 50px 50px 50px;
    }
}