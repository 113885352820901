.banner-padding-top {
    padding-top: 75px;
}

.certificado-banner-cpf {
    background-image: url(../../images/Certificado/certificadodigitalcpf.png);
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    align-items: center;
}

.certificado-banner {
    background-image: url(../../images/Certificado/certificadobanner.png);
    background-size: cover;
    height: 50vh;
    display: flex;
    align-items: center;
}

.certificado-banner-cpf h1,
.certificado-banner h1 {
    color: #FAB738;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 70px;
    margin: 0;
    line-height: 0.9;
}

.certificado-banner-cpf h2,
.certificado-banner h2 {
    color: #ffffff;
    font-family: 'Rajdhani', sans-serif;
    margin: 0;
    font-weight: bold;
    font-size: 40px;
    
}
.certificado-banner-cpf h3,
.certificado-banner h3 {
    font-family: 'Rajdhani', sans-serif;
    color: #ffffff;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
}

.container-banner-certificado {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
  
}

.certificado {
    margin: auto;
    padding: 40px 100px;
}

.certificado h1 {
    font-family: 'Rajdhani', sans-serif;
    color: #4C4C4C;
    font-size: 40px;
    margin: 0;
}

.certificado p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    color: #4C4C4C;
    line-height: 20px;
}

.certificado span {
    font-size: 15px;
    color: #354056;
    text-transform: uppercase;
}

.certificado h2 {
    color: #354056;
}

.container-certificado-home {
    width: 78%;
}

.container-certificado-home hr {
    margin: 50px 0;
}

.container-certificado-digital {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

.container-cards-certificado {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.container-busca-certificado {
    display: flex;
    flex-direction: column;
}

.content-busca-certificado {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.content-busca-certificado h2 {
    font-size: 20px;
}

.content-busca-certificado input {
    margin: 10px 15px 10px 0;
}

.content-busca-certificado label {
    color: #354056;
}

.content-certificado {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.certificado-end {
    margin: auto;
    padding: 30px 200px 0 200px;
}

.certificado-end-imagem {
    width: 65%;
    margin: auto;
    display: flex;
    align-items: center;
}

.certificado-end h2 {
    color: #354056;
}

.certificado-end p {
    color: #777;
    line-height: 30px;
}

.certificado-end img {
    width: 300px;
    margin-left: 50px;
}

.container-ecpf {
    display: flex;
    justify-content: space-between;
}

.content-ecpf {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
}

.container-ecpf img {
    width: 40px;
    height: 40px;
}
.content-card-saiba-mais {
    display: flex;
    justify-content: space-between;
}
.card-saiba-mais {
    border-radius: 10px;
    border: 1px solid #354056;
    width: 28%;
    height: 100px;
    padding: 30px 20px;
    display: flex;
    margin-bottom: 30px;
}

.card-saiba-mais p {
    color: #354056;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 42px;
}

.card-saiba-mais img {
    align-self: flex-end;
}

.beneficios-certificado {
    padding: 80px 200px;
    background-color: #273156;
    color: #fff;
}

.beneficios-certificado h2 {
    text-align: center;
}

.beneficios-certificado div {
    display: flex;
    width: 60%;
    margin: 50px auto 0 auto;
}

.beneficios-certificado img {
    width: 150px;
    height: 200px;
}

.beneficios-certificado ul {
    line-height: 30px;
    margin-left: 75px;
}

.card-contato-certificado {
    border-radius: 10px;
    border: 1px solid #354056;
    background-color: #FFF;
    width: 32%;
}

.card-contato-certificado h2 {
    font-size: 18px;
    color: black;
    margin: 30px 0 0 0;
}

.card-contato-certificado p {
    font-size: 15px;
    color: black;
    text-align: center;
    margin: 0;
}

.body-card-certificado {
    padding: 30px 40px;
    height: 45%;
}

.footer-card-certificado {
    border-top: 1px solid black;
    padding: 20px 40px;
}

.certificado-itens ul {
    margin: 0;
    padding: 0;
}

.certificado-itens li:nth-child(odd){
    list-style-type: none;
    color: #777;
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;
}
.certificado-itens li:nth-child(even){
    list-style-type: none;
    color: #777;
    display: flex;
    align-items: center;
    margin: 0 0 30px 25px;
}

.certificado-itens li img {
    width: 15px;
    margin-right: 10px;
}

.planos{
    display: flex;
    gap: 50px;
}

@media (max-width: 1440px) {
    .certificado-banner-cpf,
    .certificado-banner {
        background-position-x: center;
    }

    .certificado {
        padding: 40px 100px;
    }

    .certificado-end-imagem {
        width: 80%;
    }

    .card-saiba-mais {
        height: 135px;
    }
}

@media (max-width: 1080px) {
    .card-certificado-digital {
        width: 26%;
    }

    .certificado-end {
        padding: 50px 200px 0 200px;
    }

    .certificado-end-imagem {
        width: 100%;
    }

    .card-saiba-mais {
        height: 165px;
    }
    .beneficios-certificado{
        padding: 80px 200px;
    }
    .beneficios-certificado div {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .certificado {
        padding: 30px 50px;
    }

    .container-banner-certificado {
        width: 80%;
    }
    .beneficios-certificado {
        padding: 30px 50px;
    }
    .container-certificado-digital {
        flex-direction: column;
        margin-top: 20px;
    }

    .container-certificado-home {
        width: 100%;
    }

    .certificado-end {
        padding: 20px 50px 0 50px;
    }

    .card-certificado-digital {
        width: 25%;
    }
    .card-saiba-mais {
        width: 31%;
        height: 150px;
        padding: 5px;
    }
    .card-saiba-mais p {
        font-size: 15px;
        margin-left: 30px;
    }
    .body-card-certificado {
        padding: 10px;
        height: 45%;
    }
    .card-contato-certificado h2 {
        font-size: 16px;
        margin: 20px 0 0 0;
    }
    .card-contato-certificado p {
        font-size: 13px;
    }

}

@media (max-width: 420px) {
    .certificado-banner-cpf,
    .certificado-banner {
        padding-top: 55px;
    }
    .container-banner-certificado {
        width: 90%;
        h1 {
            font-size: 60px;
        }
    }

    .certificado-end-imagem {
        flex-direction: column;
    }

    .content-certificado {
        flex-direction: column;
    }

    .card-certificado-digital {
        width: 90%;
        margin-bottom: 20px;
    }

    .container-ecpf {
        flex-direction: column;
        align-items: center;
    }

    .content-ecpf {
        width: 80%;
    }
    .content-card-saiba-mais {
        flex-direction: column;
    }
    .card-saiba-mais {
        width: 100%;
        height: 100px;
    }
    .beneficios-certificado div {
        flex-direction: column;
        width: 100%;
    }
    .beneficios-certificado img {
        margin: auto;
    }
    .beneficios-certificado ul {
        margin-left: 0;
    }
    .card-contato-certificado {
        width: 100%;
        margin-bottom: 20px;
    }
}