.solucoes-banner {
    background-image: url(../../images/Solucoes/empresasbanner.jpg);
    background-size: cover;
    height: 60vh;
    display: flex;
    align-items: center;
}
.solucoes-fale-conosco{
    width: 60%;
    margin: auto;
}
.solucoes-banner h1 {
    color: #354056;
    font-family: 'BigNoodleTitling', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 100px;
    margin: 0;
    line-height: 80px;
}

.solucoes-banner h2 {
    color: #354056;
    font-weight: 400;
    font-size: 20px;
}

.solucoes-container {
    border: 1px solid #ffffff;
    width: 50%;
    border-radius: 0 20px 20px 0;
    background: linear-gradient(119deg, #FFF 3.61%, rgba(255, 255, 255, 0.00) 110.58%);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: end;
}

.content-solucoes {
    width: 60%;
    padding: 50px;
}

.redes-sociais {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    margin-top: 25px;
}

.redes-sociais img {
    width: 25px;
    height: 25px;
}

@media (max-width: 1080px) {
    .solucoes-container {
        width: 75%;
    }
    .content-card-empresas,
    .content-card-empresas-cinza {
        height: 325px;
    }
    .redes-sociais {
        width: 100%;
    }
    .solucoes-fale-conosco{
        width: 80%;
    }
}
@media (max-width: 720px) {
    .solucoes-container {
        width: 98%;
    }
    .card-empresas {
        width: 100%;
    }
    .redes-sociais {
        width: 50%;
    }
    .solucoes-container-cards {
        flex-direction: column;
    }
    .content-card-empresas, .content-card-empresas-cinza {
        height: 200px;
    }
    .solucoes-fale-conosco{
        width: 100%;
    }
}
@media (max-width: 450px) {
    .solucoes-banner h1 {
        font-size: 50px;
        line-height: 40px;
    }
    .content-card-empresas, 
    .content-card-empresas-cinza {
        height: 300px;
    }
}