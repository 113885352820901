.readme {
  color: #fff;
  margin: 0 auto;
  width: 80%;

  a {
    color: #00a486;
  }
}

/*_________________  Accordion
  ________________________________________*/
.accordion {
  position: relative;
  margin: 60px auto;
  width: 80%;
}

[id*="open-accordion"],
[id*="close-accordion"] {
  background: #277C82;
  border-bottom: 1px solid #fff;
  line-height: 40px;
  display: block;
  margin: 0 auto;
  position: relative;
  width: 99%;
  border-radius: 8px;
  margin-top: 15px;
}

[id*="close-accordion"] {
  display: none;
}

.accordion a {
  color: #fff;
  font-size: 1.25em;
  font-weight: normal;
  padding-left: 2%;
  text-decoration: none;
  text-shadow: none;
}

[id*="open-accordion"]:after,
[id*="close-accordion"]:after {
  border-right: 4px solid;
  border-bottom: 4px solid;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-top: 3px;
  transform: rotate(45deg);
  transition: all .2s;
  position: absolute;
  right: 10px;
  top: 10px;
}

.target-fix {
  display: block;
  top: 0;
  left: 0;
  position: fixed;
}

.accordion-content {
  background: #fff;
  height: 0;
  margin: -1px auto 0;
  padding: 0 2.5%;
  position: relative;
  overflow: hidden;
  width: 90%;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
}

.accordion span:target~.accordion-content {
  display: block;
  height: auto;
  padding-bottom: 25px;
  padding-top: 10px;
  border: 1px solid #8C56A5;
  border-radius: 0 0 15px 15px;
}

.accordion span:target~[id*="close-accordion"] {
  display: block;
}

.accordion span:target~[id*="open-accordion"] {
  display: none;
}

.accordion span:target~[id*="close-accordion"]:after {
  transform: rotate(-135deg);
}