.cadastro {
    background-color: #f1f1f1;
    color: #354056;
    padding-top: 75px;
}

.alert {
    background-color: #6277A2;
    color: #fff;
    width: 100%;
    text-align: center;
}

.container-cadastro {
    width: 30%;
    margin: auto;
    padding-bottom: 30px;
}

.input {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

.input input {
    border: none;
    padding: 10px 5px;
    border-radius: 5px;
    margin: 10px 0;
}
.input-checkbox {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.input-checkbox input {
    margin-right: 10px;
}
.input-checkbox label {
    font-size: 15px;
}
.input-checkbox a {
    color: #354056;
}

@media (max-width: 450px) {
    .cadastro {
        padding-top: 55px;
    }
}