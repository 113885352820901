.card {
    width: 30%;
    padding: 30px 35px;
    box-sizing: border-box;
    border: 1px solid #777777;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 50px;
}

.card h1 {
    color: #354056;
    text-align: center;
    font-size: 15px;
}

.card-empresas {
    border-radius: 15px;
    width: 32%;
}

.card-empresas h1 {
    color: #354056;
    font-size: 15px;
    margin: 0;
}

.bg-empresas {
    width: 100%;
    height: 230px;
    border-radius: 20px 20px 0 0;
}

.icon-empresas {
    width: 50px;
    position: absolute;
    top: -27px;
    right: 30px;
}

.content-card-empresas {
    border-radius: 15px;
    padding: 20px 35px;
    position: relative;
    top: -50px;
    background-color: white;
    height: 220px;
}

.content-card-empresas-cinza {
    border-radius: 15px;
    padding: 20px 35px;
    position: relative;
    top: -50px;
    background-color: #f1f1f1;
    height: 220px;
}

.content-card-empresas div,
.content-card-empresas-cinza div {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.content-card-empresas-cinza p {
    line-height: 30px;
}

.card-certificado-digital {
    border-radius: 20px;
    background-color: #EBEBEB;
    padding: 15px;
    width: 25%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-certificado-digital h1 {
    color: #1F1F1F !important;
    text-align: center;
    font-size: 30px !important;
    margin: 0 0 15px 0;
}

.card-certificado-digital h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    color: black;
    text-align: center;
    font-size: 13px;
}

.card-certificado-digital h3 {
    font-family: 'Rajdhani', sans-serif;
    color: #1F1F1F;
    text-align: center;
    font-size: 35px;
}

.card-certificado-digital p {
    color: black;
    font-size: 15px;
}

.card-certificado-digital select {
    border: none;
    font-size: 15px;
    color: black;
    border-color: #777;
    border-radius: 20px;
}

.content-card-certificado {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.elemento-centralizado button {
    border-radius: 20px;
    border: 2px solid black;
    padding: 15px 25px;
    background-color: #1F1F1F;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.botao-saiba-mais {
    padding: 15px !important;
    border: 2px solid #1F1F1F !important;
    background-color: transparent !important;
    color: #1F1F1F !important;
}

/* BLACK */

.elemento-centralizado-black {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-certificado-digital-black {
    border-radius: 20px;
    background-color: #1F1F1F;
    padding: 15px;
    width: 25%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-certificado-digital-black h1 {
    color: white !important;
    text-align: center;
    font-size: 30px !important;
    margin: 0 0 15px 0;
}

.card-certificado-digital-black h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    color: white !important;
    text-align: center;
    font-size: 13px;
}

.card-certificado-digital-black h3 {
    font-family: 'Rajdhani', sans-serif;
    color: white;
    text-align: center;
    font-size: 35px;
}

.card-certificado-digital-black p {
    color: white;
    font-size: 15px;
}

.card-certificado-digital-black select {
    border: none;
    font-size: 15px;
    color: black;
    border-color: #777;
    border-radius: 20px;
}

.content-card-certificado-black {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.elemento-centralizado-black button {
    border-radius: 20px;
    border: 2px solid white;
    padding: 15px 25px;
    background-color: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.botao-saiba-mais-black {
    padding: 15px !important;
    border: 2px solid white !important;
    background-color: transparent !important;
    color: white !important;
}

.card-descricao {
    border-radius: 15px;
    background-color: #f1f1f1;
    padding: 20px 40px;
}

.card-bank {
    background-color: #fff;
    border: 1px solid #BCBBB6;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
}

.card-bank h3 {
    font-weight: 400;
}

.card-bank ul {
    margin: 0;
    padding: 0;
}

.card-bank li {
    color: #777;
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 5px 0;
}

.card-bank img {
    margin-right: 10px;
}

.card-simplifique {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: "Raleway", sans-serif;
    width: 200px;
}

.card-simplifique span {
    color: #515151;
    font-size: 25px;
    font-weight: 700;
}

.card-maximizar {
    position: relative;
    width: 220px;
    height: 260px;
    padding: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-maximizar span {
    margin-top: 50px;
    color: #515151 !important;
    font-size: 25px;
    font-weight: 700;
}

.card-maximizar img {
    position: absolute;
    top: 40px;
    left: 40px;
}


.card-preco {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 600px;
    border: none;
    padding: 5vh;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-preco .conteudo h4 {
    margin-top: 20px;
    margin-bottom: 0;
    color: #515151 !important;
    font-size: 25px;
    font-weight: 700;
}

.card-preco .conteudo p {
    line-height: 1.6;
    font-weight: 400;
    margin: 0px;
}

.card-preco .preco {
    margin-top: 5vh;
}

.conteudo .preco p {
    color: #515151;
    line-height: 1.6;
    font-weight: 600;
    font-size: 12px;
}

.conteudo .preco h3 {
    font-size: 40px;
    font-weight: 700;
    color: #515151;
    margin-top: 0;
}

.conteudo .recursos {
    list-style-type: none;
    padding-left: 0;
    color: #515151;
}

.card-preco .botao-azul {
    cursor: pointer;
    width: 240px;
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 30px;
    border: none;
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    color: white;
    margin: 0px;
    font-size: 15px;
    font-weight: 300;
}

.card-preco .botao {
    background-color: transparent;
    display: flex;
    justify-content: center;
}

@media (max-width: 1440px) {
    .content-card-empresas {
        height: 270px;
    }

    .content-card-empresas-cinza {
        height: 300px;
    }
}

@media (max-width: 1024px) {
    .card-certificado-digital {
        width: 40% !important; 
        padding: 25px 15px;
    }
    .card-certificado-digital-black {
        width: 40%; 
        padding: 25px 15px;
    }
}

@media (max-width: 768px) {
    .card-certificado-digital {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .card-certificado-digital-black {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .card-certificado-digital h1 {
        font-size: 18px;
    }
}