.methodus {
    margin: auto;
    padding: 80px 200px;
}
.methodus img {
    margin-bottom: 20px;
}
.methodus h2 {
    color: #354056;
    font-size: 24px;
    text-align: center;
    margin-bottom: 50px;
}
.methodus-contabilidade h2 {
    color: #354056;
    font-size: 24px;
}
.methodus h3 {
    color: #354056;
    margin: 0;
}
.methodus p,
.methodus-contabilidade p {
    color: #777;
    line-height: 30px;
}
.methodus-contabilidade {
    margin: auto;
    padding: 80px 200px 0 200px;
}
.container-agilize {
    width: 50%;
    margin: auto;
}
.container-methodus {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
}
.container-methodus img {
    width: 400px;
}
.content-methodus p {
    margin-bottom: 30px;
}

@media (max-width: 1440px) {
    .methodus {
        padding: 80px 200px;
    }
    .methodus-contabilidade {
        padding: 80px 200px 0 200px;
    }
    .container-methodus {
        width: 70%;
    }
}
@media (max-width: 1080px) {
    .methodus {
        padding: 50px 100px;
    }
    .methodus-contabilidade {
        padding: 50px 100px 0 100px;
    }
    .container-methodus {
        width: 80%;
    }
}
@media (max-width: 720px) {
    .methodus {
        padding: 30px 50px;
    }
    .methodus-contabilidade {
        padding: 30px 50px 0 50px;
    }
    .container-methodus {
        width: 100%;
    }
}
