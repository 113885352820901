.razoes {
    margin: auto;
    padding: 80px 200px;
    background-color: #F1F1F1;
}

.razoes h2 {
    text-align: center;
    color: #354056;
    font-size: 24px;
    margin-bottom: 50px;
}

.razoes h3 {
    color: #354056;
    font-size: 20px;
}

.razoes p {
    color: #777;
    line-height: 30px
}

.container-razoes {
    display: flex;
    justify-content: space-between;
}

.card-razoes {
    padding: 30px 35px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #fafafa;
    width: 26%;
    margin-bottom: 20px;
}

.card-razoes-inteiro {
    padding: 30px 35px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #fafafa;
    width: 95%;
    margin-bottom: 20px;
}

.card-razoes-inteiro h3 {
    text-align: center;
}

@media (max-width: 1440px) {
    .razoes {
        padding: 80px 200px;
    }

    .card-razoes-inteiro {
        width: 93%;
    }

    .card-razoes {
        width: 25%;
    }
}

@media (max-width: 1080px) {
    .razoes {
        padding: 50px 100px;
    }
}

@media (max-width: 720px) {
    .razoes {
        padding: 50px 50px;
    }
    .container-razoes {
        flex-direction: column;
    }
    .card-razoes {
        width: 90%;
    }
}

@media(max-width: 450px){
    .card-razoes {
        width: 80%;
    }
    .card-razoes-inteiro {
        width: 80%;
    }
    .container-beneficios-fiscal {
        flex-direction: column;
    }
}