main {
    font-family: "Raleway", sans-serif;
}

.busca {
    width: 100%;
    color: #515151;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.conteudo-busca {
    margin-top: 170px;
    margin-bottom: 170px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    gap: 45px;

}


.conteudo-busca h1 {
    font-size: 65px;
    color: #515151;
    font-weight: 750;
    margin: 0px;
}

.conteudo-busca span {
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.conteudo-busca p {
    line-height: 1.6;
    font-weight: 550;
    margin: 0px;
}

.botao-azul {
    cursor: pointer;
    padding: 17px;
    border-radius: 30px;
    border: none;
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    color: white;
    margin: 0px;
}


.background-azul {
    position: absolute;
    z-index: -1;
    right: 0px;
    width: 600px;
    height: 950px;
}

.logo-ebuscar {
    position: relative;
    width: 400px;
    right: 60px;
    top: 170px;
}

@media (max-width: 768px) {
    .background-azul, .logo-ebuscar {
        display: none;
    }
    .conteudo-busca {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 20px;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .conteudo-busca h1 {
        font-size: 35px;
    }
}

@media (max-width: 1024px) {
    .conteudo-busca {
        width: 100%;
        justify-content: start;
        align-items: start;
    }
    .background-azul, .logo-ebuscar {
        display: none;
    }
}

/* simplifique */

.simplifique {
    position: relative;
    z-index: -2;
    background-color: #F8F8F8;
}

.head-simplifique {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 90px;
    gap: 30px;
    color: #515151;
}

.head-simplifique h1 {
    font-size: 50px;
    color: #515151;
    font-weight: 750;
    margin: 0px;
}

.head-simplifique span {
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.head-simplifique p {
    line-height: 1.6;
    font-weight: 550;
    margin: 0px;
}

.cards-simplifique {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 90px;
}

@media (max-width: 768px) {
    .head-simplifique {
        padding: 20px;
    }
    .head-simplifique h1 {
        font-size: 30px;
    }
    .cards-simplifique {
        margin-top: 50px;
        gap: 100px;
        justify-content: center;
    }
}

/* documentos */
.documentos {
    color: #515151;
    padding-bottom: 40px;
}

.head-documentos {
    position: relative;
    width: 100%;
    height: 750px;
}

.documentos-coleta {
    position: absolute;
    width: 460px;
    height: 320px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    top: 160px;
    right: 690px;
    border-radius: 50px;
    padding: 25px;
}

.documentos-coleta span {
    font-size: 50px;
    font-weight: 700;
}

.imagem-nuvem {
    position: absolute;
    z-index: -1;
    right: 60px;
    top: 90px;
}

@media (max-width: 1024px) {
    .head-documentos {
       display: none;
    }
}

@media (max-width: 768px) {
    .head-documentos {
       display: none;
    }
}

.maximizando {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.maximizando h2 {
    font-size: 50px;
    margin: 0px;
}

.maximizando span {
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.maximizando p {
    line-height: 1.6;
    font-weight: 550;
    margin: 0px;
}

.cards-maximizar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: start;
    gap: 20px;
    padding-top: 15vh;
    padding-bottom: 5vh;
}

@media (max-width: 768px) {
    .maximizando {
      padding: 20px;
    }
    .maximizando h2 {
        font-size: 30px;
    } 
    .cards-maximizar {
        padding-top: 0;
        margin-top: 50px;
    }
}

/* planos */


.planos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F8F8F8;
}

.planos h2 {
    font-size: 50px;
    margin: 0px;
}


.head-planos {
    margin-bottom: 20vh;
}

.head-planos p {
    text-align: center;
    line-height: 1.6;
    font-weight: 550;
    margin: 0px;
}

.cards-planos {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.cards-planos span {
    color: #515151;
    line-height: 1.6;
    font-weight: 700;
}


.conteudo .recursos li {
    margin-bottom: 10px;
    line-height: 1.6;
    font-weight: 400;
    font-size: 14px;
}
@media (max-width: 1024px) {
    .head-planos {
        margin-bottom: 80px;
    }
    .cards-planos {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    } 
}
@media (max-width: 768px) {
    .planos {
        padding: 20px;
    }
    .cards-planos {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .head-planos {
        margin-bottom: 50px;
        padding: 0;
    }

    .head-planos h2 {
        font-size: 30px;
    }
    .head-planos p {
        margin-top: 10px;
    }
}

/* costumizado */
.customizado {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.customizado h2 {
    color: #515151;
    text-align: center;
    font-size: 50px;
    margin-bottom: 60px;
}

.customizado span {
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
    .customizado h2 {
        font-size: 30px;
    }
}

/* contato */

.contato {
    background-color: #F8F8F8;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.chamado {
    width: 50%;
}

.contato h2 {
    color: #515151;
    font-size: 50px;
    margin: 0;
}

.contato span {
    background: -webkit-linear-gradient(#90B9E3, #3380A8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.form div {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
}

.form label {
    margin-right: 10px;
}

.form input {
    width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
}


.formButton {
    padding-top: 2%;
    justify-content: center !important;
}

@media (max-width: 768px) {
    .contato {
        flex-wrap: wrap;
        padding: 20px;
        margin: 0;
    }
    .chamado {
        width: 100%;
        margin-bottom: 20px;
    }
    .contato h2 {
        font-size: 30px;
    }

    .form input {
        width: 200px;
    }
}